
import ClipboardJS from 'clipboard';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

// Create an instance of Notyf
// const notyf = new Notyf({
//     duration: 3000,
//     position: {
//       x: 'right',
//       y: 'top',
//     },
//     types: [
//       {
//         type: 'warning',
//         background: 'orange',
//         icon: {
//           className: 'material-icons',
//           tagName: 'i',
//           text: 'warning'
//         }
//       },
//       {
//         type: 'error',
//         background: 'indianred',
//         duration: 4000,
//         dismissible: true
//       }
//     ]
//   });

// // Display an error notification 
// notyf.success('Please fill out the form');

/*
*
* Copy to Clipboard
*
*/
var clipboard = new ClipboardJS('.clipper');

clipboard.on('success', function (e) 
{
   let notify = new Notyf({
      duration: 4000,
      dismissible: true,
      ripple: true,
      position: { x: 'right', y: 'top', }
   });
   notify.success({
      message: e.trigger.getAttribute('data-clipboard-message'),
   });

   e.clearSelection();

});