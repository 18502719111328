import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

var myChart;

window.addEventListener('hydro-chart', event => {

    hydroChart(event);

});



function hydroChart(event)
{

    // console.log(event.detail[0].screen_width);
    // console.log(event.detail[0].labels);
    // console.log(event.detail[0].data);

    const stationData = event.detail[0].station_data;
    const labels = event.detail[0].labels;
    const dataPlots = event.detail[0].data;
    const stationName = event.detail[0].station_name;
    const channelName = event.detail[0].station_data[0].channel_name;
    const channelCode = event.detail[0].station_data[0].channel_code;
    const channelUnit = event.detail[0].station_data[0].unit;
    const maxPlot = event.detail[0].data[0].value;
    var dataPlotsMin = 100;
    var dataPlotsMax = 100;
    var maxTickLimitNum = 12;

    if(event.detail[0].screen_width <= 1023)
    {
        maxTickLimitNum = 4;
    }

    if(dataPlots.length >= 1)
    {
        dataPlotsMax = Math.max.apply(Math, event.detail[0].data);
        dataPlotsMin = Math.min.apply(Math, event.detail[0].data);
    }
    
    const data = {
        labels: labels,
        datasets: [
            {
                label: channelName + ' ('+ channelCode +')',
                backgroundColor: 'rgba(59, 130, 246, 0.05)',
                borderColor: '#3b82f6',
                borderDash: [0],
                pointRadius: 2,
                pointBackgroundColor: '#FFFFFF',
                pointBorderColor: '#3b82f6',
                pointBorderWidth: 1.5,
                pointRadius: 2,
                pointHoverRadius: 6,
                fill: false,
                tension: 0.4,
                stepped: false,
                data: dataPlots,
            },
            // {
            //     label: 'Temperature (0002)',
            //     backgroundColor: 'green',
            //     borderColor: 'red',
            //     borderDash: [0],
            //     stepped: false,
            //     data: [0, 4, 5, 8, 34, 98, 45],
            // }
        ]
    };
    
    const config = {
        type: 'line',
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false,

            hover: {
                mode: 'index',
                intersect: false
            },

            interaction: {
                mode: 'index',
                intersect: false,
            },
            
            elements: {
                point: {
                    pointHitRadius: 4,
                    hoverRadius: 4,
                    pointStyle: 'circle',
                },
                line: {
                    borderWidth: 2
                }
            },

            layout: {
                padding: {
                    right: 0,
                    left: 0,
                }
            },

            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    backgroundColor: 'rgba(59, 130, 246, 0.2)',
                    borderColor: '#343434',
                    borderWidth: 1,
                    bodyColor: '#343434',
                    titleColor: '#343434',
                    padding: 18,
                    cornerRadius: 4
                },
                zoom: {
                    zoom: {
                      wheel: {
                        enabled: true,
                      },
                      pinch: {
                        enabled: false,
                      },
                      mode: 'x',
                    },
                    pan: {
                      enabled: true,
                      mode: 'x',
                    },
                    limits: {
                      x: {
                        minRange: 3
                      },
                    },
                  },
                legend: {
                    display: true,
                    position: 'bottom',
                },
                title: {
                    display: true,
                    position: 'bottom',
                    text: stationName
                }
            },
            scales: {
                y: {
                    min: parseInt(dataPlotsMin) - 10,
                    max: parseInt(dataPlotsMax) + 10,
                    ticks: {
                        color: "#a2a4ab",
                        // stepSize: 2.5,
                        callback: function(value, index, values)
                        {
                            return value + ' ' + channelUnit.toUpperCase();
                        }
                    },

                    grid: {
                        drawTicks: true,
                        color: "#dbdbdb",
                    },

                    border: {
                        dash: [5, 10],
                    },
                },
                x: {
                    reverse: true,
                    ticks: {
                        color: "#a2a4ab",
                        maxTicksLimit: maxTickLimitNum,
                        padding: 20,
                        callback: (v, i) => i == 0 ? '' : labels[i - 1]
                    },

                    grid: {
                        display: false,
                    },

                    border: {
                        display: false,
                    },
                },
            },
        },
        plugins: {},
    };
    
    // Destroy to avoid multiple instances
    if (myChart) myChart.destroy();

    // Render Chart
    myChart = new Chart(
        document.getElementById('myChart'),
        config
    );

}
